// 04.10.2023 This file was copied from git repo belonging to this article https://www.freecodecamp.org/news/how-to-validate-forms-in-react/
//

/*-------------------------------------------------------------------
|  🐼 Input Validators 
|
|  🐯 Purpose: THIS FILE CONTAINS ALL THE VALIDATORS OBJECTS
|
|  🐸 Returns:  -
*-------------------------------------------------------------------*/

const message_required = "Dit veld is verplicht";

export const input_field_basic_date = {
  name: "date",
  label: "Datum",
  type: "date",
  id: "date",
  validation: {
    required: {
      value: true,
      message: message_required,
    },
  },
};

export const input_field_time_to_bed = {
  name: "time_to_bed",
  label: "Tijd naar bed",
  type: "time",
  id: "time",
  validation: {
    required: {
      value: false,
      message: message_required,
    },
  },
};

export const input_field_time_to_alarm = {
  name: "time_to_alarm",
  label: "Tijd wekker",
  type: "time",
  id: "time",
  validation: {
    required: {
      value: false,
      message: message_required,
    },
  },
};

export const input_field_score_mood = {
  name: "score_mood",
  label: "Score stemming",
  type: "number",
  id: "score_mood",
  validation: {
    required: {
      value: true,
      message: message_required,
    },
  },
};

export const input_field_score_sleep = {
  name: "score_sleep",
  label: "Score slaap",
  type: "number",
  id: "score_sleep",
  validation: {
    required: {
      value: true,
      message: message_required,
    },
  },
};

export const input_duration_sleep_at_night = {
  name: "duration_sleep_at_night",
  label: "Duur slaap 's nachts",
  type: "text",
  id: "duration_sleep_at_night",
  validation: {
    required: {
      value: true,
      message: message_required,
    },
  },
};

export const input_duration_sleep_at_day = {
  name: "duration_sleep_at_day",
  label: "Duur slaap 's nachts",
  type: "text",
  id: "duration_sleep_at_day",
  validation: {
    required: {
      value: true,
      message: message_required,
    },
  },
};

export const input_duration_stayed_in_bed = {
  name: "duration_stayed_in_bed",
  label: "Duur in bed gebleven",
  type: "text",
  id: "duration_stayed_in_bed",
  validation: {
    required: {
      value: true,
      message: message_required,
    },
  },
};

export const input_score_headache = {
  name: "score_headache",
  label: "Score hoofdpijn",
  type: "number",
  id: "score_headache",
  validation: {
    required: {
      value: true,
      message: message_required,
    },
  },
};

export const input_time_begin_headache = {
  name: "time_begin_headache",
  label: "Tijdstip begin hoofdpijn",
  type: "time",
  id: "time_begin_headache",
  validation: {
    required: {
      value: true,
      message: message_required,
    },
  },
};

export const input_time_end_headache = {
  name: "time_end_headache",
  label: "Tijdstip einde hoofdpijn",
  type: "time",
  id: "time_end_headache",
  validation: {
    required: {
      value: true,
      message: message_required,
    },
  },
};

export const input_is_headache_till_end_of_day = {
  name: "is_headache_till_end_of_day",
  label: "Tijdstip einde hoofdpijn",
  type: "checkbox",
  id: "is_headache_till_end_of_day",
};

export const input_sports_and_physical_activities = {
  name: "sports_and_physical_activities",
  label: "Sport en fysieke activiteit",
  type: "text",
  id: "sports_and_physical_activities",
  validation: {
    required: {
      value: true,
      message: message_required,
    },
  },
};



export const input_physical_symptoms = {
  name: "physical_symptoms",
  label: "Fysieke klachten (fysio)",
  type: "text",
  id: "physical_symptoms",
};

export const input_defecation = {
  name: "score_defecation",
  label: "Score ontlasting",
  type: "number",
  id: "score_defecation",
  validation: {
    required: {
      value: true,
      message: message_required,
    },
  },
};

export const input_medication_priadel = {
  name: "medication_priadel",
  label: "Medicatie Priadel ingenomen?",
  type: "checkbox",
  id: "medication_priadel",
};


export const input_var1 = {
  name: "var1",
  label: "Variabel veld 1",
  type: "text",
  id: "var1",
};

export const input_var2 = {
  name: "var2",
  label: "Variabel veld 2",
  type: "text",
  id: "var2",
};

export const input_var3 = {
  name: "var3",
  label: "Variabel veld 3",
  type: "text",
  id: "var3",
};

export const input_var4 = {
  name: "var4",
  label: "Variabel veld 4",
  type: "text",
  id: "var4",
};

export const input_comments = {
  name: "comments",
  label: "Opmerkingen",
  type: "text",
  id: "comments",
};

