// 04.10.2023 This file was copied from git repo belonging to this article https://www.freecodecamp.org/news/how-to-validate-forms-in-react/
/*-------------------------------------------------------------------
|  🐼 React FC Form
|
|  🐯 Purpose: RENDERS FORM CONTEXT AND INPUTS
|
|  🐸 Returns:  JSX
*-------------------------------------------------------------------*/

import { Input } from "./Input";
import { FormProvider, useForm } from "react-hook-form";
import {
  input_field_basic_date,
  input_field_time_to_bed,
  input_field_time_to_alarm,
  input_field_score_mood,
  input_field_score_sleep,
  input_duration_sleep_at_night,
  input_duration_sleep_at_day,
  input_duration_stayed_in_bed,
  input_score_headache,
  input_time_begin_headache,
  input_time_end_headache,
  input_is_headache_till_end_of_day,
  input_sports_and_physical_activities,
  input_physical_symptoms,
  input_defecation,
  input_medication_priadel,
  input_var1,
  input_var2,
  input_var3,
  input_var4,
  input_comments,
} from "../../utils/inputValidations";
import classes from "./Form.module.css";
import { useState } from "react";
import { GrMail } from "react-icons/gr";
import { BsFillCheckSquareFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {get_payload} from "./functions";

const submit_action = async (data) => {
  const is_local = true;

  const local_base_url = "http://localhost:5000";
  const remote_base_url = "[todo]";

  let base_url = "";
  if (is_local) {
    base_url = local_base_url;
  } else {
    base_url = remote_base_url;
  }

  const url = base_url;

  
  const body = get_payload(data);


  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: body,
  };

  try {
    const response = await fetch(url, options);
    const data = await response;
    console.log(data);
  } catch (error) {
    console.error(error);
  }
};

export const Form = (props) => {
  const methods = useForm();
  const navigate = useNavigate;

  const [success, setSuccess] = useState(false);

  const onSubmit = methods.handleSubmit((data) => {
   
    

    
    methods.reset();
    props.setIsSubmitted(true);


    submit_action(data);
    //navigate(`/planning/submitted/${data.name}/${data.description}`);
    setSuccess(true);
  });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => e.preventDefault()}
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <div className={classes["input-widgets-container"]}>
          <Input {...input_field_basic_date} />
          <Input {...input_field_time_to_bed} />
          <Input {...input_field_time_to_alarm} />
          <Input {...input_field_score_mood} />
          <Input {...input_field_score_sleep} />
          <Input {...input_duration_sleep_at_night} />
          <Input {...input_duration_sleep_at_day} />
          <Input {...input_duration_stayed_in_bed} />
          <Input {...input_score_headache} />
          <Input {...input_time_begin_headache} />
          <Input {...input_time_end_headache} />
          <Input {...input_is_headache_till_end_of_day} />
          <Input {...input_sports_and_physical_activities} />
          <Input {...input_physical_symptoms} />
          <Input {...input_defecation} />
          <Input {...input_medication_priadel} />
          <Input {...input_var1} />
          <Input {...input_var2} />
          <Input {...input_var3} />
          <Input {...input_var4} />
          <Input {...input_comments} />
        </div>
        <div className="mt-5">
          {/* {success && (
            <p className="font-semibold text-green-500 mb-5 flex items-center gap-1">
              <BsFillCheckSquareFill /> Form has been submitted successfully
            </p>
          )} */}
          <button onClick={onSubmit} className={classes["submit-button"]}>
            <GrMail />
            &nbsp;&nbsp;Verstuur
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default Form;
