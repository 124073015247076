// Challenge / Exercise

import Homepage from "./pages/Home";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MainNavigation from "./components/MainNavigation";
import EventsPage, {loader as eventsLoader} from "./pages/Events";
import NewEventPage from "./pages/NewEvent";
import EventDetailPage from "./pages/EventDetail";
import EditEventPage from "./pages/EditEvent";
import RootLayout from "./layouts/Root";
import EventsLayout from "./layouts/Events";
import ErrorPage from "./pages/Error";
import SubmitLogPage from "./pages/SubmitLog";
import ViewLogPage from "./pages/ViewLog";
import EventSubmittedPage from "./pages/EventSubmitted";


const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Homepage /> },
      {
        path: "log",
        //element: <EventsLayout />,
        children: [
          {
            index: true,
            element: <EventsPage />,
            // loader: eventsLoader,
            shouldRevalidate: () => true
          },
          // { path: ":eventId", element: <EventDetailPage /> },
          { path: "new", element: <SubmitLogPage /> },
          { path: "view", element: <ViewLogPage />},
          { path: "view/:x", element: <ViewLogPage />,},
          // { path: "submitted/:name/:description", element: <EventSubmittedPage /> },
          // { path: ":eventId/edit", element: <EditEventPage /> },
        ],
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
