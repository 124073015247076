import { NavLink } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";
import PageContent from "../components/PageContent";

function Homepage() {
  return (
    <>
      <h1>Welkom!</h1>
      <p>Met deze app kun je een nieuwe logregel maken voor je HealthLog!</p>
      <p>
        Ga hiervoor naar het formulier (<NavLink to="/log/new">Link</NavLink>).
      </p>
    </>
  );
}

export default Homepage;
