import { useLoaderData } from "react-router-dom";
import EventsList from "../components/EventsList";

function EventsPage() {
  const data = useLoaderData();
  // if (data.isError) {
  //   return <p>{data.message}</p>;
  // }
  // console.log('test');
  // console.log(data.events);

  // const events = data.events;

  return (
    <>
      Data: {data}
      
    </>
  );
}
export default EventsPage;

export async function loader() {
  const backend_url = "http://backend.planning-app.jona-goes-nineve.host";
  const response = await fetch(backend_url + "/events");

  if (!response.ok) {
    // return { isError: true, message: "Could not fetch events." };
    throw new Response(JSON.stringify({ message: "could not fetch events." }), {
      status: 500,
    });
  } else {
    const resData = await response.json();
    // console.log(resData);

    return resData;
  }
}
