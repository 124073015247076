
export function get_payload(input) {


    let payload = {
        fields: [
          [
            "ID",
            "integer"
          ],
          [
            "BasicDate",
            "date"
          ],
          [
            "TimeToBed",
            "time"
          ],
          [
            "TimeToAlarm",
            "time"
          ],
          [
            "ScoreMood",
            "integer"
          ],
          [
            "ScoreSleep",
            "integer"
          ],
          [
            "DurationSleepAtNight",
            "float"
          ],
          [
            "DurationSleepAtDay",
            "float"
          ],
          [
            "DurationStayedInBed",
            "float"
          ],
          [
            "Score_Headache",
            "integer"
          ],
          [
            "TimeBeginHeadache",
            "time"
          ],
          [
            "TimeEndHeadache",
            "time"
          ],
          [
            "IsHeadacheTillEndOfDay",
            "boolean"
          ],
          [
            "SportsAndPhysicalActivities",
            "text"
          ],
          [
            "PhysicalSymptoms",
            "text"
          ],
          [
            "Defecation",
            "integer"
          ],
          [
            "MedicationPriadel",
            "boolean"
          ],
          [
            "Var1",
            "text"
          ],
          [
            "Var2",
            "text"
          ],
          [
            "Var3",
            "text"
          ],
          [
            "Var4",
            "text"
          ],
          [
            "Comments",
            "text"
          ]
        ],
        values: [
          null,
          input.date,
          input.time_to_bed,
          input.time_to_alarm,
          parseInt(input.score_mood),
          parseInt(input.score_sleep),
          parseFloat(input.duration_sleep_at_night),
          parseFloat(input.duration_sleep_at_day),
          parseFloat(input.duration_stayed_in_bed),
          parseFloat(input.score_headache),
          input.time_begin_headache,
          input.time_end_headache,
          input.is_headache_till_end_of_day,
          input.sports_and_physical_activities,
          input.physical_symptoms,
          input.defecation,
          input.medication_priadel,
          input.var1,
          input.var2,
          input.var3,
          input.var4,
          input.comments
        ]
      }
      
    return JSON.stringify(payload);

}
