// 04.10.2023 This file was copied from git repo belonging to this article https://www.freecodecamp.org/news/how-to-validate-forms-in-react/
/*-------------------------------------------------------------------
|  🐼 Function isFormInvalid
|
|  🐯 Purpose: CHECKS IF FORM IS VALID OR NOT
|
|  🐸 Returns:  OBJECT
*-------------------------------------------------------------------*/

export const isFormInvalid = err => {
  if (Object.keys(err).length > 0) return true
  return false
}
