import React, { useRef, useState } from "react";

// import DatePicker from "react-datepicker";

const JonasDatePicker = (props) => {
  // <-- from https://www.makeuseof.com/react-date-picker/
  const [date, setDate] = useState("");
  const dateInputRef = useRef(null);

  const handleChange = (e) => {
    let temp_date = e.target.value;
    setDate(temp_date);
    props.onChanged(temp_date);
  };

  return (
    <div>
      <input type="date" onChange={handleChange} ref={dateInputRef} />
      <p>Selected Date: {date}</p>
    </div>
  );
};

// --->

export default JonasDatePicker;

/* 

  const [startDate, setStartDate] = useState(new Date());

  var phrase = "test";
  const changed_date_handler = (date) => {
    phrase = "you clicked " ; //+ date.toString() + "!";
    //setStartDate(date);
  };

  return (
    <>

      <input type="date" value="2017-06-01" />

      <DatePicker
        //showIcon
        selected={startDate}
        onChange={changed_date_handler}
      />
      {<p>{phrase}</p>}
    </>
  );
} */
