import { Outlet, useNavigation } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";
import PageContent from "../components/PageContent";

function RootLayout() {
const navigation = useNavigation();

  return (
    <>
      <MainNavigation />
      <main>


      <PageContent title="[todo] set title">
      {navigation.state === 'loading' && <p>Loading...</p>}
        <Outlet />
      </PageContent>


       
      </main>
    </>
  );
}
export default RootLayout;