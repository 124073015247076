// 04.10.2023 This file was copied from git repo belonging to this article https://www.freecodecamp.org/news/how-to-validate-forms-in-react/
import { useNavigate } from "react-router-dom";
import Form from "./Form";


function SubmitLogFormWrapper({setIsSubmitted}) {
  const navigate = useNavigate();
  function cancelHandler() {
    navigate("..");
  }

  return <Form {...{setIsSubmitted}}/>;
}

export default SubmitLogFormWrapper;
