import React from 'react';
import ReactDOM from 'react-dom/client';

// below statement is necessary to let the datepicker work. see also https://github.com/Hacker0x01/react-datepicker/issues/879#issuecomment-482473054
import "react-datepicker/dist/react-datepicker.css"

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
