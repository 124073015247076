import { useLoaderData, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import JsonView from "@uiw/react-json-view";
import classes from "./ViewLog.module.css";
import JonasDatePicker from "../components/date-picker/JonasDatePicker";

// https://www.freecodecamp.org/news/how-to-validate-a-date-in-javascript/
const isDateValid = (dateStr) => {
  return !isNaN(new Date(dateStr));
};

const getSelectedDateByUrl = () => {
  const testvar = window.location.pathname;
  const re = new RegExp("^.*(/.*)$");
  const search = testvar.match(re);
  const group = search[1];
  const date = group.substring(1);
  return date;
};

function ViewLogPage() {

  let backend_url = '';
  const isLocal = false;

  if (isLocal) {
    backend_url = "http://localhost:5000";
  }
  else {
    backend_url = "https://backend.healthlog.seesink.online"
  }



  const initialDate = "2024-06-12"


  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(backend_url + "/daily?date=" + selectedDate)
      .then(response => response.json())
      .then(json => setData(json))
      .catch(error => console.error(error));
  }, [selectedDate]);

  // let data = loader(selectedDate);

  const date_changed_handler = (newDate) => {
    setSelectedDate(newDate);
  };
  

  // const data = useLoaderData();



  return (
    <>
    selected date: {selectedDate}
      <h1>Logregel bekijken</h1>
      <>
      <JonasDatePicker onChanged={date_changed_handler}></JonasDatePicker>
      <div className={classes.json_container}>
        {data !== null && <JsonView displayDataTypes={false} value={data} />}
      </div>
    </>
    </>
  );
}

export default ViewLogPage;



/* // [todo] here I would like to use the selectedDate state! but that data is out of scope. how do i get it into scope of the data loader function?

async function loader(date) {
  const backend_url = "http://localhost:5000";
  
  debugger;
  if (!isDateValid(date)) {
    let date = getSelectedDateByUrl();
  }
  //const date = "2023-11-23"; // [todo] make this dynamic
  const response = await fetch(backend_url + "/daily?date=" + date);

  if (!response.ok) {
    // return { isError: true, message: "Could not fetch events." };
    throw new Response(JSON.stringify({ message: "could not fetch events." }), {
      status: 500,
    });
  } else {
    // const resData = await response.json();
    // console.log(resData);
    // debugger;
    const resData = response.json();

    return resData;
  }
} */
