
import classes from "./SubmitEvent.module.css";
import "../index.css";
import { useState } from "react";
import SubmitLogFormWrapper from "../components/forms/SubmitLogFormWrapper";

function SubmitLogPage() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  let content;
  if (isSubmitted) {
    content = (
      <>
        <h1>Verstuurd!</h1>
      </>
    );
  } else {
    content = (
      <>
        <h1>Nieuwe logregel aanmaken</h1>

        <div className={`${classes.form_box} dynamic_width`}>
          <SubmitLogFormWrapper {...{ setIsSubmitted }} />
        </div>
      </>
    );
  }

  return content;
}

export default SubmitLogPage;
