import { NavLink } from "react-router-dom";
import classes from "./MainNavigation.module.css";
// import classes from "./test.css";
import "../index.css";

function MainNavigation() {
  return (
    <header className={classes.header}>
      <div className={classes.header_content}>
        <NavLink to="/">
          <img
            className={`${classes.header_content_image} dynamic_width`}
            src={require("../images/logo.png")}
          />
        </NavLink>
      </div>

      <div className={classes.header_content_navigation}>
        <nav>
          <ul className={classes.list}>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
                end
              >
                Startpagina
              </NavLink>
            </li>
            <li>
              <NavLink
                to="log/new"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
                end
              >
                Nieuwe logregel
              </NavLink>
            </li>
            <li>
              <NavLink
                to="log/view"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
                end
              >
                Toon logregel
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default MainNavigation;
