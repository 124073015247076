import { NavLink } from "react-router-dom";

import { useParams } from "react-router-dom";

function EventSubmittedPage() {
  const params = useParams();

  return (
    <>
      <h1>Verstuurd!</h1>
      <p>Bedankt voor je aanvraag. Je ontvangt spoedig een bevestiging.</p>
      <p>
        Naam: {params.name}
        Omschrijving: {params.description}
        {/* Verwachte werkzaamheden: {params.expected_services}
        Akkoord met voorwaarden:
        {params.inquiry_needed} */}
      </p>
    </>
  );
}

export default EventSubmittedPage;
