// 04.10.2023 This file was copied from git repo belonging to this article https://www.freecodecamp.org/news/how-to-validate-forms-in-react/
/*-------------------------------------------------------------------
|  🐼 React FC Input
|
|  🐯 Purpose: RE-USEABLE INPUT COMPOENT
|
|  🐸 Returns:  JSX
*-------------------------------------------------------------------*/


import { findInputError, isFormInvalid } from "../../utils";
import { useFormContext } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";
import { MdError } from "react-icons/md";
import classes from "./Input.module.css"

export const Input = ({
  name,
  label,
  type,
  id,
  placeholder,
  validation,
  multiline,
  checkbox,
  className,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);

  const input_tailwind =
    "p-5 font-medium rounded-md w-full border border-slate-300 placeholder:opacity-60";

  const getInputWidget = (is_multiline) => {
    let input_widget;

   if (is_multiline) {
      input_widget = (
        <textarea
          id={id}
          type={type}
       
          placeholder={placeholder}
          {...register(name, validation)}
        ></textarea>
      );
    } else {
      input_widget = (
        <input
          id={id}
          type={type}
          style={{'maxWidth':"100px"}}
          placeholder={placeholder}
          {...register(name, validation)}
        />
      );
    }

    return input_widget;
  };

  return (
    <div className={classes['input-widget']}>
      <div className={classes['label-container']}>
        <label htmlFor={id}>
          {label}
        </label>
        <AnimatePresence mode="wait" initial={false}>
          {isInvalid && (
            <InputError
              message={inputErrors.error.message}
              key={inputErrors.error.message}
            />
          )}
        </AnimatePresence>
      </div>
      <div className={classes['input-container']}>
      {getInputWidget(multiline)}
      </div>
    </div>
  );
};

const InputError = ({ message }) => {
  return (
    <motion.p
      className="flex items-center gap-1 px-2 font-semibold text-red-500 bg-red-100 rounded-md"
      {...framer_error}
    >
      <MdError />
      {message}
    </motion.p>
  );
};

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};
